import { logger } from '@root/utilities/logger';
import { Controller } from '@hotwired/stimulus';
import addElement from '../utils/add_element';

export default class extends Controller {
  static targets = ['form'];

  connect() {
    this.campaignId = this.data.get('campaignId');

    logger.debug('Connecting the attribute controller');
  }

  submit() {
    const div = this.element.querySelector('input[id="attribute-submit"]');
    div.click();
  }

  update() {
    // Submits the form to the server as an AJAX request
    const form = this.formTarget;
    const formData = new FormData(form);

    fetch(form.action, {
      method: form.method,
      body: formData,
    });
  }

  create() {
    // Submits the form to the server as an AJAX request
    const form = this.formTarget;
    addElement({ form });
  }
}
