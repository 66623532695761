import { Controller } from '@hotwired/stimulus';
import { edit_smart_tag_path } from '@root/routes';

// Connects to data-controller="smart-tag-preview"
export default class extends Controller {
  connect() {}

  showReasoning(e) {
    alert(e.target.dataset.reasoning);
  }

  edit(e) {
    const editPath = edit_smart_tag_path(e.target.dataset.id);
    window.parent.Turbo.visit(editPath, { frame: 'modal' });
  }
}
