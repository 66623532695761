import { errorHandler } from './error_tracking';
import { logger } from './logger';

const controllerNames = [];

function checkAlreadyRegistered(controllerName) {
  if (controllerNames.includes(controllerName)) {
    const error = new Error('Duplicate controller names found');
    logger.error(`Duplicate controller names found, will not be registered: ${controllerName}`);
    errorHandler(error, { detail: { controllerName } });
    return true;
  }
  return false;
}

function registerNestedControllers({ controllers, application }) {
  controllers.forEach((controller) => {
    const registerAs = controller.name.split('--').pop();
    if (checkAlreadyRegistered(registerAs)) {
      return;
    }
    logger.debug(`Registering nested controller: ${registerAs}`);
    application.register(registerAs, controller.module.default);
    controllerNames.push(registerAs);
  });
}

function registerComponentControllers({ controllers, application }) {
  controllers.forEach((controller) => {
    const registerAs = controller.name.replace(/^\.\.--\.\.--components--/, '').replace(/--controller\.js$/, '');
    if (checkAlreadyRegistered(registerAs)) {
      return;
    }
    logger.debug(`Registering component controller: ${registerAs}`);
    application.register(registerAs, controller.module.default);
    controllerNames.push(registerAs);
  });
}

function registerControllers({ nestedControllers, componentControllers, application }) {
  controllerNames.splice(0, controllerNames.length);
  registerNestedControllers({ controllers: nestedControllers, application });
  registerComponentControllers({ controllers: componentControllers, application });
}

export { registerControllers, registerComponentControllers, registerNestedControllers, controllerNames };
