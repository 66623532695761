import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { estimate_audience_smart_tag_templates_snippet_instructions_path } from '@root/routes';
import csrfHeaders from '@root/utilities/csrfHeaders';

// Connects to data-controller="settings"
export default class extends Controller {
  static targets = ['targetingText', 'targetAudienceSize', 'audienceEstimateLink', 'specificAudienceDetail'];

  static values = {
    campaignId: Number,
    smartTagTemplateId: Number,
    calculatedAudience: String,
  };

  toggleSpecificAudienceDetail() {
    this.specificAudienceDetailTargets.forEach((target) => target.classList.toggle('hidden'));
    this.targetingTextTarget.classList.toggle('invisible');
  }

  showEstimationLink() {
    this.audienceEstimateLinkTarget.classList.remove('hidden');
    this.targetAudienceSizeTarget.classList.add('hidden');
  }

  calculateAudienceEstimate(event) {
    event.preventDefault();
    const params = {
      smart_tag_template_id: this.smartTagTemplateIdValue,
      target_audience: this.targetingTextTarget.value,
      campaign_id: this.campaignIdValue,
    };

    fetch(estimate_audience_smart_tag_templates_snippet_instructions_path(params), {
      method: 'POST',
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
        ...csrfHeaders(),
      },
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
