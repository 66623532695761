import tinymce from 'tinymce/tinymce';
import { logger } from '@root/utilities/logger';
import { Application } from '@hotwired/stimulus';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { clearTurboFrame } from '@root/utilities/utils';
import BaseController from './base_controller';
import { errorHandler } from '../utilities/error_tracking';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';

import register from '../editor_controllers/register';
import { autosave_campaign_path } from '../routes';
import csrfHeaders from '../utilities/csrfHeaders';
import consumer from '../channels/consumer';
import { reloadElement } from '../../components/utils/add_element';
import { defaultSetup } from '../utilities/tinymce_utils';

TimeAgo.addDefaultLocale(en);

export default class extends BaseController {
  static targets = ['form', 'text_area', 'lastSaved', 'combination', 'locked', 'lockedBy', 'unlocked'];

  static outlets = ['attribute-modal'];

  static values = {
    readOnly: Boolean,
    campaignId: Number,
    lastUpdated: String,
  };

  get isDirty() {
    const activeContent = tinymce.activeEditor.getContent();

    // TODO - we might need to bring back a library like cheerio to clean up the content
    const cleanContent = (content) => content;

    const cleanedContent = cleanContent(activeContent);
    return cleanedContent !== cleanContent(this.savedValue);
  }

  connect() {
    this.setup('controller.connect');
    this.visitHandler = (event) => this.beforeLeave(event);
    clearTurboFrame('segmentation-page');
    this.lastAutoSaveAttempt = new Date();
    window.addEventListener('beforeunload', this.visitHandler);
    document.addEventListener('turbo:before-visit', this.visitHandler);

    this.updateLastSavedInterval = setInterval(() => {
      this.lastUpdatedValueChanged();
    }, 5000);

    document.addEventListener('turbo:load', (event) => {
      if (event.detail.url.match(/campaigns\/\d+\/edit/)) {
        this.setup('turbo:load');
      }
    });
    this.combinationTargets.forEach((target) => {
      const os = navigator.userAgent;
      target.textContent = os.includes('Mac') ? 'Cmd + Option +' : 'Ctrl + Alt +';
    });
    this.setupLockPolling();
    this.setupSmartTagTemplateUpdates();
  }

  lastUpdatedValueChanged() {
    const timeAgo = new TimeAgo('en-US');
    const lastUpdated = new Date(this.lastUpdatedValue);
    this.lastSavedTarget.textContent = timeAgo.format(lastUpdated);
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.visitHandler);
    document.removeEventListener('turbo:before-visit', this.visitHandler);
    clearInterval(this.updateLastSavedInterval);
    this.tinymceDisconnect();
    this.clearLockPolling();
    this.clearSmartTagTemplateUpdates();
  }

  shortcut(e) {
    const shortcuts = {
      '†': 'summary',
      t: 'summary',
      å: 'attribute',
      a: 'attribute',
      ß: 'segmentation',
      s: 'segmentation',
    };
    const shortcutKey = e.key.toLowerCase();
    const modals = document.querySelectorAll('.modal');
    const backdropModals = document.querySelectorAll('.backdrop-modal');
    const activeModal = [...modals].filter((modal) => !modal.classList.contains('hidden'));
    const activeBackdropModal = [...backdropModals].filter((modal) => !modal.classList.contains('hidden'));
    if (shortcutKey in shortcuts && (e.metaKey || e.ctrlKey) && e.altKey) {
      e.preventDefault();
      if (activeModal.length >= 1) {
        activeModal[0].classList.add('hidden');
        activeBackdropModal[0].classList.add('hidden');
        return;
      }
      const action = shortcuts[shortcutKey];
      this.linkClick(action);
    } else if (shortcutKey === 'enter') {
      this.modalKeydown(e, activeModal);
    }
  }

  modalKeydown(e, activeModal) {
    const modalMethods = {
      'attribute-modal-body': 'attributeKeydown',
      'segmentation-modal-body': 'segmentationKeydown',
      'summary-modal-body': 'summaryKeydown',
    };
    if (activeModal[0].id in modalMethods) {
      this[modalMethods[activeModal[0].id]](e);
    }
  }

  segmentationKeydown(e) {
    e.preventDefault();
    const submit = document.getElementById('generate-audiences')
      ? document.getElementById('generate-audiences')
      : document.getElementById('save-audiences');
    submit.click();
  }

  attributeKeydown() {
    if (this.attributeModalOutletElement.value === 'Create') {
      this.attributeModalOutlet.create();
    } else {
      this.attributeModalOutlet.update();
    }
  }

  summaryKeydown(e) {
    // When editing summaries as require shift + enter to submit the form automatically.
    // This should maybe the the same across across all forms? @myxoh
    if (!e.shiftKey) {
      return false;
    }
    e.preventDefault();
    let submit = document.getElementById('create-summary');
    if (!submit) {
      submit = document.getElementById('fine-tune');
    }
    return submit.click();
  }

  beforeLeave(event) {
    if (this.isDirty) {
      if (!window.confirm('Leave site? Changes you made may not be saved.')) {
        event.preventDefault();
        if (event.type === 'beforeunload') {
          event.returnValue = '';
        }
      } else {
        this.tinymceDisconnect();
      }
    } else {
      this.tinymceDisconnect();
    }
  }

  tinymceDisconnect() {
    try {
      tinymce.remove();
    } catch {
      logger.debug('Error removing TinyMCE, perhaps not present?');
    }
  }

  autoSave(force = false) {
    if (force || new Date() - this.lastAutoSaveAttempt > 5000) {
      this.lastAutoSaveAttempt = new Date();
      if (this.isDirty && tinymce.activeEditor.mode.get() === 'design') {
        const campaignId = this.campaignIdValue;
        const currentTabId = window.Singulate.current_tab_id;
        const editor = tinymce.activeEditor;
        fetch(autosave_campaign_path(campaignId), {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            ...csrfHeaders(),
          },
          body: JSON.stringify({ current_tab_id: currentTabId, campaign: { html: editor.getContent() } }),
        }).then((res) => {
          if (res.status === 200) {
            this.savedValue = editor.getContent();
            this.lastUpdatedValue = new Date().toISOString();
          }
        });
      }
    }
  }

  setup() {
    const self = this;

    // If the editor is already set up there's no point in initing again, we return early.
    if (tinymce.activeEditor) {
      // This is added to manually show the editor in the case where navigating with browser buttons has resulted in the
      // editor being hidden.
      if (tinymce.activeEditor.isHidden()) {
        tinymce.activeEditor.show();
      }
      return;
    }

    tinymce.init({
      ...defaultSetup,
      menubar: 'edit insert view format table tools help',
      toolbar: ['bold italic underline forecolor fontfamily fontsize align numlist bullist image link code undo redo'],
      height: '91vh',
      forced_root_block: 'p',
      custom_elements: '~i',
      setup: (editor) => {
        editor.addShortcut('meta+alt+a', 'Opens WoW Tag modal', () => {
          if (document.querySelector('#liquid-modal-id') != null) {
            this.linkClick('liquid');
          } else {
            this.linkClick('attribute');
          }
        });
        editor.addShortcut('meta+alt+s', 'Opens Segmentation modal', () => {
          this.linkClick('segmentation');
        });
        editor.addShortcut('meta+alt+t', 'Opens Summary modal', () => {
          this.linkClick('summary');
        });
        editor.on('change NodeChange Dirty', () => {
          self.autoSave();
        });
        editor.mode.set(this.readOnlyValue === true ? 'readonly' : 'design');
        editor.on('init', () => {
          // Enables stimulus inside the editor:
          const iframeWindow = editor.iframeElement.contentWindow;
          const iframeDocument = iframeWindow.document;
          const application = Application.start(iframeDocument.body);
          application.handleError = errorHandler;
          iframeWindow.Stimulus = application;
          register(application);

          this.savedValue = editor.getContent();
        });
      },
    });
  }

  deleteSmartTagTemplate(e) {
    const { id } = e.detail;
    // Window confirm:
    if (window.confirm('Are you sure you want to delete this smart tag?')) {
      // Finds element with id:
      // TODO - replace code with non turbo-tags.
      // TODO(ciscou): data-smart-tag-template-id constant
      tinymce.activeEditor.dom.select(`.smart-tag-parent[data-smart-tag-template-id="${id}"]`).forEach((element) => {
        // Removes the element from the editor
        tinymce.activeEditor.dom.remove(element);
      });
      this.autoSave(true);
    }
  }

  submit() {
    // Prevents autosave attempt when we're about to save anyway, fixes issue where smart tag templates are frozen as
    // "Generating...."
    this.lastAutoSaveAttempt = new Date();
    const form = this.formTarget;
    form.requestSubmit();
  }

  linkClick(modalType) {
    const link = document.getElementById(`${modalType}-modal-id`);
    link.click();
    setTimeout(() => {
      const modal = document.getElementById(`${modalType}-modal`);
      if (modal) {
        modal.focus();
      }
    }, 200);
  }

  setupSmartTagTemplateUpdates() {
    this.smartTagTemplateUpdateSubscription = consumer.subscriptions.create(
      {
        channel: 'SmartTagTemplateUpdateChannel',
        campaign_id: this.campaignIdValue,
      },
      {
        connected() {
          logger.debug('Connected to SmartTagTemplateUpdateChannel');
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          reloadElement(data.smart_tag_template.id);
        },
      },
    );
  }

  clearSmartTagTemplateUpdates() {
    this.smartTagTemplateUpdateSubscription.unsubscribe();
  }

  setupLockPolling() {
    const locked = this.lockedTarget;
    const lockedBy = this.lockedByTarget;
    const unlocked = this.unlockedTarget;
    const currentTabId = window.Singulate.current_tab_id;
    const currentUserId = window.Singulate.user_id;
    this.lockPollingSubscription = consumer.subscriptions.create(
      { channel: 'CampaignChannel', campaign_id: `${this.campaignIdValue}`, current_tab_id: currentTabId },
      {
        received: (campaign) => {
          if (campaign.changed === 'locked_by') {
            let lockedByAnotherUser = false;
            let lockedByYouOnAnotherTab = false;
            if (campaign.locked_by_user_id) {
              if (campaign.locked_by_user_id !== currentUserId) {
                lockedByAnotherUser = true;
              } else if (campaign.locked_by_tab_id) {
                lockedByYouOnAnotherTab = campaign.locked_by_tab_id !== currentTabId;
              }
            }
            const lockedForMe = lockedByAnotherUser || lockedByYouOnAnotherTab;
            unlocked.classList.toggle('hidden', lockedForMe);
            locked.classList.toggle('hidden', !lockedForMe);
            lockedBy.innerText = campaign.locked_by_user_name;
            tinymce.activeEditor.mode.set(lockedForMe ? 'readonly' : 'design');
            if (lockedForMe && tinymce.activeEditor.isDirty()) {
              tinymce.activeEditor.resetContent();
            }
          }

          if (campaign.changed === 'html') {
            if (!tinymce.activeEditor.isDirty()) {
              tinymce.activeEditor.setContent(campaign.html);
            }
            if (campaign.disabled === true) {
              tinymce.activeEditor.mode.set('readonly');

              const iframeDocument = tinymce.activeEditor.iframeElement.contentDocument;
              iframeDocument.querySelectorAll('span.delete-smart-tag').forEach((span) => span.remove());
              iframeDocument.addEventListener(
                'click',
                (e) => {
                  e.stopImmediatePropagation();
                  e.preventDefault();
                },
                true,
              );
            }
          }
        },
      },
    );

    // we receive RT updates, but let's poll in case the user that held the lock
    // stopped making changes but didn't close the tab or navigated away, and
    // the lock finally expired.
    const TEN_SECONDS = 10000;
    this.lockPollingInterval = setInterval(() => {
      this.lockPollingSubscription.perform('poll_lock_status');
    }, TEN_SECONDS);
  }

  clearLockPolling() {
    this.lockPollingSubscription.unsubscribe();
    clearInterval(this.lockPollingInterval);
  }
}
