import { logger } from '@root/utilities/logger';
import { full_page_email_path } from '@root/routes';
import BaseController from './base_controller';
// Connects to data-controller="email-preview"
export default class extends BaseController {
  static targets = ['iframe'];

  static values = {
    emailId: Number,
  };

  toggleTags(event) {
    logger.debug(`Toggling for ${this.emailIdValue}`);
    const nextValue = event.target.checked;
    this.updateCookies(nextValue);
    this.updateIframe(nextValue);
  }

  updateCookies(nextValue) {
    logger.debug(`Setting show_previews_with_tags=${nextValue}`);
    document.cookie = `show_previews_with_tags=${nextValue}; path=/;`;
  }

  updateIframe(nextValue) {
    this.iframeTarget.contentWindow.location.replace(full_page_email_path(this.emailIdValue, { with_tags: nextValue }));
  }
}
