import { TurboMount } from 'turbo-mount';
import { registerComponent } from 'turbo-mount/svelte';
import { registerControllers } from '@root/utilities/controller_registration';
// eslint-disable-next-line import/extensions
import editorElementControllers from '../../components/editor_elements/**/controller.js';
import icons from '../../components/sui/icon/svgs/*.svelte';

export default function register(application) {
  const turboMount = new TurboMount({ application });
  icons.forEach((icon) => {
    const name = icon.name.split('--').pop().replace('.svelte', '');
    registerComponent(turboMount, name, icon.module.default);
  });
  registerControllers({ nestedControllers: [], componentControllers: editorElementControllers, application });
}
