import { Controller } from '@hotwired/stimulus';
// eslint-disable-next-line import/no-extraneous-dependencies
import { leave, toggle } from 'el-transition';
import DropdownValue from '@components/dropdown_with_icon/DropdownValue.svelte';

export default class extends Controller {
  static targets = [
    'menu',
    'button',
    'input',
    'dropdownValue',
    'option',
    'dropdownAdditionalInfo',
    'selectedOptionText',
  ];

  static values = { options: Object, defaultValue: String, label: Boolean };

  connect() {
    const { text, icon } = this.optionsValue[this.defaultValueValue] || {};
    this.createDropdownComponent({ key: this.defaultValueValue, text, icon, label: this.labelValue });
  }

  changeValue(event) {
    const inputValue = event.currentTarget.dataset.value;
    this.inputTarget.value = inputValue;
    const { icon, text } = this.optionsValue[inputValue];
    this.createDropdownComponent({ key: inputValue, text, icon, label: this.labelValue });
  }

  createDropdownComponent({ key, text, icon, label }) {
    if (this.dropdownComponentInstance) {
      this.dropdownComponentInstance.$destroy();
    }
    this.dropdownComponentInstance = new DropdownValue({
      target: this.dropdownValueTarget,
      props: {
        icon,
        key,
        text,
        label,
      },
    });
  }

  toggle() {
    toggle(this.menuTarget);
  }

  hide(event) {
    const buttonClicked = this.buttonTarget.contains(event.target);

    if (!buttonClicked) {
      leave(this.menuTarget);
    }
  }
}
