import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import {
  edit_smart_tag_templates_snippet_instruction_path,
  generate_summary_smart_tag_templates_snippet_instructions_path,
} from '@root/routes';
import csrfHeaders from '@root/utilities/csrfHeaders';

// Connects to data-controller="settings"
export default class extends Controller {
  static targets = [
    'content',
    'header',
    'main',
    'fallbackText',
    'fullContent',
    'sendFallback',
    'campaignId',
    'nullifyOnEmptyFullContent',
    'fallbackTitle',
  ];

  static outlets = ['sidebar'];
  // TODO finish setting up this outlet

  displayFullContent(event) {
    const content = this.contentTarget;
    const main = this.mainTarget;

    if (content.classList.contains('hidden')) {
      content.classList.remove('hidden');
      this.headerTarget.style.height = '100%';
      main.classList.add('pointer-events-none');
      main.classList.add('opacity-20');
    } else {
      if (event.currentTarget.id !== 'save') content.children[0].value = '';
      content.classList.add('hidden');
      this.headerTarget.style.height = '80px';
      main.classList.remove('pointer-events-none');
      main.classList.remove('opacity-20');
    }
  }

  generateSummary(e) {
    e.preventDefault();
    this.displayFullContent(e);

    if (this.fullContentTarget.value.trim() !== '') {
      const fallback = this.sendFallbackTarget.checked && this.fallbackTextTarget.value.trim() === '';
      fetch(generate_summary_smart_tag_templates_snippet_instructions_path(), {
        method: 'PATCH',
        body: JSON.stringify({
          full_content: this.fullContentTarget.value,
          campaign_id: this.campaignIdTarget.value,
          fallback,
        }),
        headers: {
          Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
          'Content-Type': 'application/json',
          ...csrfHeaders(),
        },
      })
        .then((response) => response.text())
        .then((html) => Turbo.renderStreamMessage(html));
    } else {
      this.nullifyOnEmptyFullContentTargets.forEach((target) => {
        if (target) {
          target.value = null;
          target.innerText = null;
        }
      });
    }
  }

  edit(e) {
    const url = edit_smart_tag_templates_snippet_instruction_path(e.detail.id);
    Turbo.visit(url, { frame: 'modal' });
  }

  updateInputVisibility(event) {
    const inputElement = this[event.params.target];
    inputElement.classList.toggle('invisible', !event.params.visible);
    inputElement.required = event.params.visible;
  }

  updateFallbackTitleText(event) {
    this.fallbackTitleTarget.textContent = event.params.fallbackTitleText;
  }
}
