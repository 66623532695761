import tinymce from 'tinymce/tinymce';
import BaseController from '@root/controllers/base_controller';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';
// Connects to data-controller="email-preview"
import { defaultSetup } from '@root/utilities/tinymce_utils';
import { svgToBase64Img } from '@root/utilities/utils';
import AddWowTag from '@root/icons/editor/addWowTag.svg';
import AddSnippet from '@root/icons/editor/addSnippet.svg';
import register from '@root/editor_controllers/register';
import { Application } from '@hotwired/stimulus';
import { errorHandler } from '@root/utilities/error_tracking';
import {
  edit_smart_tag_templates_attribute_instruction_path,
  edit_smart_tag_templates_liquid_instruction_path,
} from '@root/routes';

export default class extends BaseController {
  static targets = ['editor', 'form', 'continue', 'allowEmpty'];

  connect() {
    this.setup();
  }

  setup() {
    if (tinymce.activeEditor) {
      tinymce.activeEditor.remove();
    }

    tinymce.init({
      ...defaultSetup,
      toolbar: [
        'wowtag snippet bold italic underline forecolor fontfamily fontsize align numlist bullist image link code undo redo',
      ],
      height: '100%',
      setup: (editor) => {
        const wowTag = svgToBase64Img({ id: 'segmentWowtagIcon', svg: AddWowTag });
        const snippet = svgToBase64Img({ id: 'segmentSnippetIcon', svg: AddSnippet });
        editor.ui.registry.addIcon('tag', wowTag.outerHTML);
        editor.ui.registry.addIcon('snippet', snippet.outerHTML);
        editor.ui.registry.addButton('wowtag', {
          icon: 'tag',
          onAction: () => this.openWowTagModal(),
        });

        editor.ui.registry.addButton('snippet', {
          icon: 'snippet',
          onAction: () => this.openSnippetTag(),
        });
        editor.on('init', () => {
          const iframeWindow = editor.iframeElement.contentWindow;
          const iframeDocument = iframeWindow.document;
          const application = Application.start(iframeDocument.body);
          application.handleError = errorHandler;
          iframeWindow.Stimulus = application;
          register(application);
          this.enableOrDisableParagraph();
        });
      },
    });
  }

  enableOrDisableParagraph() {
    if (this.allowEmptyTarget.checked) {
      this.savedParagraph = tinymce.activeEditor.getContent();
      tinymce.activeEditor.setContent('');
      tinymce.activeEditor.mode.set('readonly');
    } else {
      tinymce.activeEditor.mode.set('design');
      if (this.savedParagraph) {
        tinymce.activeEditor.setContent(this.savedParagraph);
      }
    }
  }

  openWowTagModal() {
    // Remove once we sunset attribute-modals
    if (window.parent.document.getElementById(`liquid-modal-id`)) {
      const link = window.parent.document.getElementById(`liquid-modal-id`);
      link.click();
    } else {
      const link = window.parent.document.getElementById(`attribute-modal-id`);
      link.click();
    }
  }

  openSnippetTag() {
    const link = window.parent.document.getElementById('snippet-modal-id');
    link.click();
  }

  fillSubmitType() {
    // When submitting with Done button it fills the "submit_type" field to finish and close the flow
    const hiddenInput = this.formTarget.querySelector('input[name="submit_type"]');
    if (hiddenInput) {
      hiddenInput.value = 'done';
    }
  }

  // TODO<@myxoh>: DEPRECATED for editLiquid - once we sunset attribute-modals
  // Remove this function and replace editLiquid with this.
  edit(e) {
    this.state = 'editing';
    const turboFrame = window.parent.document.getElementById('modal');
    turboFrame.src = edit_smart_tag_templates_attribute_instruction_path(e.detail.id);
  }

  editLiquid(e) {
    this.state = 'editing';
    const turboFrame = window.parent.document.getElementById('modal');
    turboFrame.src = edit_smart_tag_templates_liquid_instruction_path(e.detail.id);
  }

  deleteSmartTagTemplate(e) {
    const { id } = e.detail;
    // Window confirm:
    if (window.confirm('Are you sure you want to delete this smart tag?')) {
      // Finds element with id:
      // TODO - replace code with non turbo-tags.
      tinymce.activeEditor.dom.select(`.smart-tag-parent[data-smart-tag-template-id="${id}"]`).forEach((element) => {
        // Removes the element from the editor
        tinymce.activeEditor.dom.remove(element);
      });
      this.autoSave(true);
    }
  }

  toggleRules() {
    const arrowIcon = document.getElementById('arrow-down');
    const element = document.getElementById('rules-paragraph');
    if (element.classList.contains('text-nowrap')) {
      element.classList.remove('text-nowrap');
      arrowIcon.classList.replace('rotate-0', 'rotate-180');
    } else {
      element.classList.add('text-nowrap');
      arrowIcon.classList.replace('rotate-180', 'rotate-0');
    }
  }

  submitForm() {
    // When submitting over the form we do not want to redirect
    // NOTE(jkogara): Removed this as unsure WTF it does :smile:
    // this.continueTarget.value = '';
    this.formTarget.submit();
  }

  async submitFormAndRemoveEditor() {
    await this.submitForm();

    if (tinymce.activeEditor) {
      tinymce.activeEditor.remove();
    }
  }
}
