import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="turbo-modal"
export default class TurboModalController extends Controller {
  static values = { closeOnSubmit: Boolean };

  static targets = ['sidebarContent'];

  connect() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.hideSidebar();
      }
    });
    requestAnimationFrame(() => {
      this.showSidebar();
    });
    document.addEventListener('click', (event) => {
      if (!this.sidebarContentTarget.contains(event.target)) {
        this.hideSidebar();
      }
    });
  }

  showSidebar() {
    this.sidebarContentTarget.classList.remove('translate-x-full');
    this.sidebarContentTarget.classList.add('translate-x-0');
  }

  hideSidebar() {
    if (!this.element.parentElement) {
      return;
    }

    if (this.element.parentElement.id === 'sidebar') {
      this.element.parentElement.removeAttribute('src'); // it might be nice to also remove the modal SRC
      [].forEach.call(this.element.parentElement.children, (el) => {
        el.remove();
      });
      this.element.remove();
    }
  }
}
