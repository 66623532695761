const TINYMC_FONT_FAMILIES = [
  'Andale Mono=andale mono,times',
  'Arial=arial,helvetica,sans-serif',
  'Arial Black=arial black,avant garde',
  'Book Antiqua=book antiqua,palatino',
  'Comic Sans MS=comic sans ms,sans-serif',
  'Courier New=courier new,courier',
  'Georgia=georgia,palatino',
  'Helvetica=helvetica',
  'Impact=impact,chicago',
  'Inter=inter, sans-serif',
  'Symbol=symbol',
  'Tahoma=tahoma,arial,helvetica,sans-serif',
  'Terminal=terminal,monaco',
  'Times New Roman=times new roman,times',
  'Trebuchet MS=trebuchet ms,geneva',
  'Verdana=verdana,geneva',
  'Webdings=webdings',
  'Wingdings=wingdings,zapf dingbats',
].join(';');

export const defaultSetup = {
  menubar: false,
  promotion: false,
  visual: false, // defaults visual aids to disabled.
  suffix: '.min',
  base_url: '/assets/tinymce',
  plugins: ['image', 'link', 'code', 'lists', 'emoticons', 'visualblocks', 'table'],
  selector: '.tinymce',
  // eslint-disable-next-line no-undef
  content_css: typeof editorCssPath === 'undefined' ? '' : editorCssPath,
  content_style: `
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://site-assets.fontawesome.com/releases/v6.5.2/css/all.css');
  `,
  branding: false,
  statusbar: false,
  font_family_formats: TINYMC_FONT_FAMILIES,
};
